import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { update } from 'ramda';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { EVENTS, TABS } from '../../consts';
import messages from '../../intl/messages';
import { hideModal } from '../../store/modal/actions';
import { addNewViolation, setViolationReview } from '../../store/model/actions';
import { getCurrentExam, getCurrentCheckpoints, getEvaluations } from '../../store/model/selectors';
import { getTypeFilter } from '../../store/ui/selectors';
import { msToTime } from '../../utils/formatDate';
import negEvalsOnly from '../../utils/negEvalsOnly';
import sortArrByObjProp from '../../utils/sortArrByObjProp';
import Timespan from '../Timespan';
import { getCurrentTab } from '../ViolationsList';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles()((theme) => ({
	fixSelectLabel: {
		top: '-7px',
		left: '14px',
	},
	selectTitle: {
		width: '100%',
	},
	selectStreamType: {
		width: '100%',
	},
}));

const getViolationTimestamp = (time) => time[0] * 3600000 + time[1] * 60000 + time[2] * 1000;

const getVideoCurrentTime = () =>
	msToTime(Math.floor(document.querySelectorAll('video.examVideo')?.[0]?.currentTime ?? 0) * 1000, true).split(':');

const filterByTab = (items, tab) => (!items || !tab ? [] : Object.values(items).filter((item) => item.tab === tab));

const AddViolationModal = () => {
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const exam = useSelector(getCurrentExam);
	const typeFilter = useSelector(getTypeFilter);

	const [time, setTime] = useState(getVideoCurrentTime());

	const tabs = Object.values(TABS);
	const [tab, setTab] = useState(getCurrentTab(typeFilter));

	const checkpoints = useSelector(getCurrentCheckpoints);
	const [checkpointId, setCheckpointId] = useState(undefined);
	const [checkpointText, setCheckpointText] = useState('');

	const evals = useSelector(getEvaluations);
	const evalsNegative = negEvalsOnly(evals);
	const [evaluationId, setEvaluationId] = useState(undefined);
	const [evaluationText, setEvaluationText] = useState('');

	useEffect(() => {
		setCheckpointId(undefined);
		setEvaluationId(undefined);
		setCheckpointText('');
		setEvaluationText('');
	}, [tab]);

	const handleAddViolation = async () => {
		const violationId = await dispatch(
			addNewViolation({
				examId: exam.id,
				checkpointId,
				createdAt: new Date(exam[EVENTS.STREAM_STARTED_AT]).getTime() + getViolationTimestamp(time),
			})
		);

		await dispatch(
			setViolationReview({
				reviewerNote: evals[evaluationId].text,
				violationId,
				review: 'NOK',
				evaluationId,
			})
		);

		dispatch(hideModal());
	};

	const handleTimeChange = (e, position) => setTime(update(position, Number(e.target.value), time));

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.addViolation} />}
			saveBtnTitle={<FormattedMessage {...messages.addViolation} />}
			onSave={handleAddViolation}
			isSaveBtnDisabled={!checkpointId || !evaluationId}
		>
			<Grid container spacing={1}>
				<Grid item xs={9}>
					<Timespan className={classes.timespan} handleTimeChange={handleTimeChange} time={time} />
				</Grid>
				<Grid item xs={3}>
					<FormControl className={classes.selectStreamType}>
						<InputLabel className={classes.fixSelectLabel} id="select-streamType">
							<FormattedMessage {...messages.type} />
						</InputLabel>
						<Select
							label={<FormattedMessage {...messages.type} />}
							labelId="select-streamType"
							onChange={(e) => setTab(e.target.value)}
							value={tab}
							variant="outlined"
						>
							{tabs.map((value) => (
								<MenuItem key={value} value={value}>
									<FormattedMessage {...messages[value]} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
			<br />
			<Autocomplete
				options={sortArrByObjProp('streamType', filterByTab(checkpoints, tab))}
				freeSolo
				fullWidth
				blurOnSelect
				getOptionLabel={({ violationMessage }) => violationMessage}
				value={checkpointId}
				inputValue={checkpointText}
				onInputChange={(e, v) => setCheckpointText(v)}
				renderInput={(params) => (
					<TextField
						{...params}
						label={<FormattedMessage {...messages.violationTitle} />}
						fullWidth
						variant="outlined"
					/>
				)}
				onChange={(e, v) => v && setCheckpointId(v.id)}
			/>
			<br />
			<Autocomplete
				options={sortArrByObjProp('code', filterByTab(evalsNegative, tab))}
				freeSolo
				fullWidth
				blurOnSelect
				getOptionLabel={({ text }) => text}
				value={evaluationId}
				inputValue={evaluationText}
				onInputChange={(e, v) => setEvaluationText(v)}
				renderInput={(params) => (
					<TextField
						{...params}
						label={<FormattedMessage {...messages.violationComment} />}
						fullWidth
						variant="outlined"
					/>
				)}
				onChange={(e, v) => v && setEvaluationId(v.id)}
			/>
		</SaveCloseModalTemplate>
	);
};

export default AddViolationModal;
