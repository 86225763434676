import { ContentCopy, Forward, PersonAdd, PowerOff, SyncProblem } from '@mui/icons-material';
import { Button, Grid, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import {
	AUTHORIZED_ACTIONS,
	ENTITIES,
	EXAM,
	externalMonitoringUrl,
	FILTER_VIOLATIONS,
	JOB_STATUS,
	ROUTES,
	STREAM_TYPES,
	USER,
} from '../../consts';
import messages from '../../intl/messages';
import { fetchUsers } from '../../store/auth/actions';
import { getUserId, getUserRole, getUsers } from '../../store/auth/selectors';
import { hideModal, showAssignReviewModal, showConfirmModal, showConfirmReviewModal } from '../../store/modal/actions';
import {
	assignReviews,
	assignReviewToCommissioner,
	fetchCheckpoints,
	fetchExam,
	fetchFaceUrl,
	fetchIdUrl,
	fetchQueueReview,
	fetchRoomVideoUrl,
	fetchScreenUrl,
	fetchSpecializedQueueReview,
	fetchWebcamUrl,
	finishExam,
	postponeReview,
	resetExamPreviewUrls,
	setDefinitionId,
	unassignReview,
	restartProcessing,
} from '../../store/model/actions';
import { getCurrentExam, getDefinitionId, getExamId } from '../../store/model/selectors';
import { NOTIF_ACTION_TYPES } from '../../store/notif/actions';
import { getNotifErrors } from '../../store/notif/selectors';
import { setCurrentListTab, setVideoSeek, setVideoState } from '../../store/ui/actions';
import { getAllowReviewForExamId, getTypeFilter } from '../../store/ui/selectors';
import { ScrollToTopOnMount } from '../../utils/scroll';
import checkUserAuthorization from '../../utils/userRights';
import Breadcrumbs from '../Breadcrumbs';
import {
	getCommissionerReviewIcon,
	getJobStatusIcon,
	getReviewerReviewIcon,
	getStatusIcon,
	useStyles as useStylesIcons,
} from '../Exams/ExamsList';
import UniButtton from '../UniButtton';
import ViolationsList from '../ViolationsList';
import Instructions from './Instructions';
import LeftBoxContent from './LeftBoxContent';
import LeftBoxControls from './LeftBoxControls';
import Notes from './Notes';
import PreviousExams from './PreviousExams';
import RightBoxControls from './RightBoxControls';

const useStyles = makeStyles()((theme) => ({
	bounding: {
		'& .floatRight': {
			float: 'right',
		},
		'& .alignRight': {
			textAlign: 'right',
		},
		'& .instructions': {
			marginTop: '10px',
			marginBottom: '15px',
		},
		'& .examControls': {
			margin: '0 0 0 10px',
			float: 'right',
		},
		'& .right-controls-container': {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			'& button': {
				marginBottom: '5px',
			},
		},
		'& .space-between': {
			justifyContent: 'space-between',
		},
		'& .leftBlock': {
			padding: '20px',
			'& .MuiButtonBase-root': {
				margin: '0 0.5rem 1.25rem 0',
			},
			'& .controls': {
				'& .MuiFormControl-root': {
					background: theme.palette.primary.main,
					margin: 0,
					width: '33.33%',
					'& .MuiInputBase-root': {
						borderRadius: 0,
						'& .MuiInputBase-input': {
							color: theme.palette.secondary.main,
							WebkitTextFillColor: theme.palette.secondary.main,
						},
					},
					'& .MuiInputLabel-filled': {
						color: 'white',
					},
				},
			},
		},
		'& .rightBlock': {
			padding: '20px',
			'& .MuiButtonBase-root': {
				margin: '0 0.5rem 1.25rem 0',
			},
			'& .MuiFormControl-root': {
				margin: '0 0 15px 0',
				width: '25%',
				minWidth: '150px',
			},
		},
		'& .userFilterSelect': {
			'& .MuiInputBase-root': {
				marginBottom: '15px',
			},
			'& .MuiSelect-root': {
				width: '150px',
				padding: '12px 15px 10px 15px',
				fontSize: '0.9rem',
			},
		},
		'& .item-group': {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			'& button': {
				minWidth: '35px',
			},
		},
		'& .thumbs': {
			display: 'flex',
			'& .MuiButtonBase-root': {
				margin: 0,
				padding: 0,
				minWidth: '40px',
			},
		},
	},
	isQueuePadding: {
		padding: '25px',
	},
	noQueue: {
		textAlign: 'center',
		marginTop: '200px',
	},
}));

const ExamPreview = ({ isReviewerQueue, isCommissionerQueue }) => {
	const intl = useIntl();
	const navigate = useNavigate();
	const [noQueue, setNoQueue] = useState(false);
	const userRole = useSelector(getUserRole);
	const [userFilter, setUserFilter] = useState(
		checkUserAuthorization(userRole, AUTHORIZED_ACTIONS.REVIEW_AS_COMMISSIONER)
			? FILTER_VIOLATIONS.SUSP
			: FILTER_VIOLATIONS.ALL
	);
	const { classes } = useStyles();
	const { classes: classesIcons } = useStylesIcons();
	const dispatch = useDispatch();
	const typeFilter = useSelector(getTypeFilter);
	const allowReviewForExamId = useSelector(getAllowReviewForExamId);
	const definitionId = useSelector(getDefinitionId);
	const { examId: examIdUrl } = useParams();
	const examIdStore = useSelector(getExamId);
	const examId = examIdUrl || examIdStore;
	const exam = useSelector(getCurrentExam);
	const users = useSelector(getUsers);
	const userId = useSelector(getUserId);
	const hw = isEmpty(exam?.hwInfo ?? {}) ? null : exam.hwInfo;

	const [debounced, setDebounced] = useState(false);

	const allowPostponeExam =
		((checkUserAuthorization(userRole, AUTHORIZED_ACTIONS.REVIEW_AS_REVIEWER) && !exam?.reviewerReview) ||
			(checkUserAuthorization(userRole, AUTHORIZED_ACTIONS.REVIEW_AS_COMMISSIONER) && !exam?.commissionerReview)) &&
		!exam?.postponed;
	const errors = useSelector(getNotifErrors);

	const assignedTo = exam?.assignedTo;
	const isAssignedToMe = assignedTo === userId;
	const isLoading = allowReviewForExamId !== examId;

	const isSuperadmin = userRole === USER.ROLES.SUPERADMIN;

	const canReviewExams = checkUserAuthorization(userRole, [
		AUTHORIZED_ACTIONS.REVIEW_AS_REVIEWER,
		AUTHORIZED_ACTIONS.REVIEW_AS_COMMISSIONER,
	]);

	// Depending on how ExamPreview is entered, reviewerQueue or commissionerQueue is fetched
	// (no matter the userRole since reviewer does not have access to the commissionerQueue tab)
	const fetchFromQueue = isReviewerQueue
		? async () => await dispatch(fetchQueueReview(userId))
		: isCommissionerQueue
		? async () => dispatch(fetchSpecializedQueueReview(userId))
		: null;

	useEffect(() => {
		dispatch(fetchUsers());
	}, [dispatch]);

	useEffect(() => {
		return () => {
			localStorage.removeItem(ENTITIES.POSTPONE_REVIEW);
		};
	}, [dispatch, navigate]);

	useEffect(() => {
		if (
			(isCommissionerQueue && errors.FETCH_SPECIALIZED_QUEUE_REVIEW) ||
			(isReviewerQueue && errors.FETCH_QUEUE_REVIEW)
		) {
			setNoQueue(true);
			navigate(ROUTES.DASHBOARD.PATH);
		} else setNoQueue(false);
	}, [dispatch, errors, isCommissionerQueue, isReviewerQueue, navigate]);

	useEffect(() => {
		if (definitionId && examId) {
			dispatch(
				fetchExam(
					!isCommissionerQueue
						? () =>
								setTimeout(() => {
									dispatch(setCurrentListTab(ENTITIES.DEFINITIONS));
								}, 500)
						: null,
					definitionId,
					examId
				)
			);
		}
	}, [definitionId, dispatch, examId, navigate, isCommissionerQueue]);

	useEffect(() => {
		const dontReplacePostponedPreview = !localStorage.getItem(ENTITIES.POSTPONE_REVIEW);

		if (isReviewerQueue && dontReplacePostponedPreview) {
			localStorage.removeItem(ENTITIES.POSTPONE_REVIEW);

			const initQueue = async () => {
				const definitionId = await dispatch(fetchQueueReview(userId));

				dispatch(fetchCheckpoints(definitionId));
			};
			initQueue();
		}

		if (isCommissionerQueue) {
			const initQueue = async () => {
				const definitionId = await dispatch(fetchSpecializedQueueReview(userId));

				dispatch(fetchCheckpoints(definitionId));
			};

			initQueue();
		}
	}, [dispatch, examId, isCommissionerQueue, isReviewerQueue, userId, userRole]);

	useEffect(() => {
		// seek to room check start if room tab
		if (exam?.roomCheckpointStartedAt && typeFilter === ENTITIES.VIDEO_ROOM) {
			const seekTo = Date.parse(exam.roomCheckpointStartedAt) - Date.parse(exam.streamStartedAt);
			dispatch(setVideoSeek(seekTo));
		} else if (exam?.examCheckpointStartedAt && typeFilter === STREAM_TYPES.WEBCAM) {
			const seekTo = Date.parse(exam.examCheckpointStartedAt) - Date.parse(exam.streamStartedAt);
			dispatch(setVideoSeek(seekTo));
		}
		setTimeout(() => {
			dispatch(setVideoState('play'));
		}, 1);
	}, [dispatch, exam, typeFilter]);

	useEffect(() => {
		if (examId) {
			dispatch(resetExamPreviewUrls());
			dispatch(fetchWebcamUrl(examId));
			dispatch(fetchScreenUrl(examId));
			dispatch(fetchRoomVideoUrl(examId));
			dispatch(fetchIdUrl(examId));
			dispatch(fetchFaceUrl(examId));
		}
	}, [dispatch, examId]);

	useEffect(() => {
		if (exam && exam.definitionId && definitionId !== exam.definitionId) dispatch(setDefinitionId(exam.definitionId));
	}, [definitionId, dispatch, exam, exam?.definitionId]);

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);
		document.addEventListener('keyup', handleKeyUp);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
			document.addEventListener('keyup', handleKeyUp);
		};
	}, []);

	// Disallow print/pdf
	const handleKeyDown = (e) => {
		if ((e.ctrlKey || e.key === 'Meta') && e.key === 'p') {
			e.cancelBubble = true;
			e.preventDefault();
			e.stopImmediatePropagation();
		}
	};

	// Disallow print screen
	const handleKeyUp = (e) => {
		if (e.key === 'PrintScreen') navigator.clipboard.writeText('');
	};

	// special exit rules https://dev.azure.com/borndigitalai/Born%20Digital/_sprints/backlog/Born%20Digital%20All%20projects/Born%20Digital/Sprint%2019?workitem=2612
	const exit = (event) => {
		const navigatorHistory = localStorage.getItem('navigatorHistory')?.split(',') ?? [];
		const originIsQueue =
			navigatorHistory[1] === ROUTES.EXAMS_PREVIEW_REVIEWER.PATH ||
			navigatorHistory[1] === ROUTES.EXAMS_PREVIEW_COMMISSIONER.PATH;
		const originIsExams =
			navigatorHistory?.[1]?.includes(ROUTES.EXAMS.PATH) && navigatorHistory?.[0]?.includes(ROUTES.EXAMS.PATH);

		if (originIsQueue && (event === 'postpone' || event === 'send')) {
			fetchFromQueue();
		} else if (originIsExams) {
			navigate(-1);
		} else {
			navigate(ROUTES.DASHBOARD.PATH);
		}
	};

	const handlePostponeReview = async () => {
		await dispatch(postponeReview({ examId, userId }));
		exit('postpone');
	};

	const handleUnassignReview = async () => {
		await dispatch(unassignReview({ examId }));
		exit('unassign');
	};

	const handleAssignReviews = async (_, userId) => {
		await dispatch(assignReviews([examId], userId, intl));
	};

	const handleSendAndClose = () => {
		dispatch(showConfirmReviewModal(() => exit('sendAndClose')));
	};

	const handleSendReview = () => {
		dispatch(showConfirmReviewModal(() => exit('send')));
	};

	const handleFinishExam = () => {
		dispatch(finishExam({ examId }));
		dispatch(hideModal());
		navigate(ROUTES.DASHBOARD.PATH);
	};

	const handleCopyExamUrl = () => {
		const examUrl = `${window.location.protocol}//${window.location.host}/exams/${definitionId}/${examId}`;

		navigator.clipboard
			.writeText(examUrl)
			.then(() =>
				dispatch({
					type: NOTIF_ACTION_TYPES.SUCCESS,
					payload: {
						type: NOTIF_ACTION_TYPES.SUCCESS,
						message: `${examUrl} ${intl.formatMessage(messages.copiedIntoClipboard)}`,
					},
				})
			)
			.catch((error) =>
				dispatch({
					type: NOTIF_ACTION_TYPES.ERROR,
					payload: {
						type: NOTIF_ACTION_TYPES.ERROR,
						message: `Error: ${examUrl}, ${error}`,
					},
				})
			);
	};

	useEffect(() => {
		setDebounced(false);
		setTimeout(() => {
			setDebounced(true);
		}, 2000);
	}, []);

	return !noQueue ? (
		<>
			<ScrollToTopOnMount />
			<div className={`${(isReviewerQueue || isCommissionerQueue) && classes.isQueuePadding}`}>
				<Grid container spacing={0} className={classes.bounding} direction="row" alignItems="flex-start">
					<Grid item xs={12} lg={4}>
						<Breadcrumbs disableControls={isReviewerQueue || isCommissionerQueue} />
					</Grid>
					{!debounced ? (
						<Grid item xs={12} lg={8} className="alignRight">
							<FormattedMessage {...messages.loading} />
						</Grid>
					) : (
						<Grid
							item
							xs={12}
							lg={8}
							className={`${!isAssignedToMe ? 'space-between' : 'alignRight'} right-controls-container`}
						>
							{checkUserAuthorization(userRole, AUTHORIZED_ACTIONS.VIEW_REVIEW_THUMBS) && (
								<div className={classesIcons.bounding}>
									<div className="thumbs">
										{exam?.status && getStatusIcon(exam.status, intl)}
										{exam?.jobStatus === JOB_STATUS.FAILED
											? getJobStatusIcon(
													exam.jobStatus,
													intl,
													`${intl.formatMessage(messages.processingStatus)}: ${exam.jobStatus} - ${
														exam.jobFailedReason
													}`
											  )
											: exam?.jobStatus && getJobStatusIcon(exam.jobStatus, intl)}
										{getReviewerReviewIcon(exam?.reviewerReview)}
										{getCommissionerReviewIcon(exam?.commissionerReview)}
										{getCommissionerReviewIcon(exam?.finalReview, true, exam?.finalViolationMessage)}
									</div>
								</div>
							)}
							<div className="item-group">
								{!isAssignedToMe && assignedTo && (
									<span>
										<FormattedMessage {...messages.assignedTo} />: {users[assignedTo]?.email} (
										<FormattedMessage {...messages[users[assignedTo]?.role || USER.ROLES.REVIEWER]} />)
									</span>
								)}
								{!assignedTo && checkUserAuthorization(userRole, AUTHORIZED_ACTIONS.SELF_ASSIGN_REVIEW) && (
									<Button
										variant="contained"
										color="primary"
										size="small"
										onClick={() => dispatch(assignReviewToCommissioner(exam.id, userId))}
									>
										<FormattedMessage {...messages.assignToReview} />
									</Button>
								)}
								{definitionId && examId && (
									<UniButtton
										onClick={handleCopyExamUrl}
										icon={<ContentCopy size="small" />}
										tooltip={<FormattedMessage {...messages.copyExamUrl} />}
									/>
								)}
								{/* Admin actions */}
								{checkUserAuthorization(userRole, AUTHORIZED_ACTIONS.ASSIGN_REVIEW) && (
									<UniButtton
										onClick={() => dispatch(showAssignReviewModal([examId], handleAssignReviews))}
										icon={<PersonAdd size="small" />}
										tooltip={<FormattedMessage {...messages.assignReviews} />}
									/>
								)}

								{exam?.status === EXAM.RUNNING && checkUserAuthorization(userRole, AUTHORIZED_ACTIONS.FINISH_EXAM) && (
									<UniButtton
										onClick={() =>
											dispatch(
												showConfirmModal({
													handler: handleFinishExam,
													text: <FormattedMessage {...messages.finishExamPrompt} />,
													title: <FormattedMessage {...messages.finishExam} />,
													button: <FormattedMessage {...messages.finishExam} />,
												})
											)
										}
										icon={<PowerOff size="small" />}
										tooltip={<FormattedMessage {...messages.finishExam} />}
									/>
								)}
								{exam?.externalId && (
									<UniButtton
										onClick={() => window.open(externalMonitoringUrl(exam.externalId), '_blank')}
										icon={<Forward size="small" alt="ScioLink Monitoring" />}
										tooltip={<FormattedMessage {...messages.monitoring} />}
									/>
								)}
								{isSuperadmin && (
									<UniButtton
										onClick={() => dispatch(restartProcessing([exam.id], intl))}
										icon={<SyncProblem size="small" />}
										tooltip={<FormattedMessage {...messages.processExams} />}
									/>
								)}
								{/* My actions */}
								{isAssignedToMe && canReviewExams && (
									<>
										<Button
											className="examControls"
											variant="contained"
											color="primary"
											size="small"
											onClick={handleSendReview}
										>
											<FormattedMessage {...messages.done} />
										</Button>

										<Button
											className="examControls"
											variant="contained"
											color="primary"
											size="small"
											onClick={handleSendAndClose}
										>
											<FormattedMessage {...messages.sendAndClose} />
										</Button>

										<Button
											className="examControls"
											variant="contained"
											color="primary"
											size="small"
											onClick={handleUnassignReview}
										>
											<FormattedMessage {...messages.unassignReview} />
										</Button>

										<Button
											className="examControls"
											variant="contained"
											color="primary"
											size="small"
											onClick={handlePostponeReview}
											disabled={!allowPostponeExam}
										>
											<FormattedMessage {...messages[exam?.postponed ? 'postponedReview' : 'postponeReview']} />
										</Button>
									</>
								)}
							</div>
						</Grid>
					)}
					<Grid item xs={12} className="instructions">
						<Instructions />
					</Grid>
					<Grid
						container
						spacing={3}
						className={classes.bounding}
						direction="row"
						justifyContent="center"
						alignItems="flex-start"
					>
						<Grid item xs={6}>
							<Paper elevation={3} className="leftBlock">
								<LeftBoxControls />
								<LeftBoxContent />
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={3} className="rightBlock">
								<RightBoxControls
									isAssignedToMe={isAssignedToMe}
									isLoading={isLoading}
									setUserFilter={setUserFilter}
									userFilter={userFilter}
								/>
								<br />
								<ViolationsList isAssignedToMe={isAssignedToMe} userFilter={userFilter} examId={examId} />
							</Paper>
						</Grid>
						<Grid item xs={12}>
							<Notes examId={examId} />
						</Grid>
						{hw && (
							<Grid item xs={12}>
								<Paper elevation={3} style={{ padding: '20px' }}>
									<FormattedMessage {...messages.operatingSystem} />
									{`: ${hw.os.platform === 'darwin' ? 'macOS' : hw.os.platform} ${hw.os.release} (${
										hw.cpu.arch
									}), RAM: ${Math.floor(hw.ram.totalmem / 1024 ** 3)} GB,
							CPU: ${hw.cpu.model} - ${hw.cpu.cores} core(s)`}
								</Paper>
							</Grid>
						)}
						{checkUserAuthorization(userRole, AUTHORIZED_ACTIONS.VIEW_PREVIOUS_EXAMS) && (
							<Grid item xs={12}>
								<PreviousExams />
							</Grid>
						)}
					</Grid>
				</Grid>
			</div>
		</>
	) : (
		<div className={classes.noQueue}>
			<FormattedMessage {...messages.noQueue} />
		</div>
	);
};

ExamPreview.propTypes = {
	isCommissionerQueue: PropTypes.bool,
	isReviewerQueue: PropTypes.bool,
};

export default ExamPreview;
