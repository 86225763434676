import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { getGridNumericOperators, getGridStringOperators, GridFilterInputSingleSelect } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { isEmpty } from 'ramda';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../intl/messages';
import { msToTime } from '../utils/formatDate';

dayjs.extend(duration);

export const jsKeywordsToWords = (list) =>
	JSON.parse(JSON.stringify(list).replace(/true/g, '"Ano"').replace(/false/g, '"Ne"'));

const EmptyNotEmptyFilterValue = (e, list, compareEmpty, allowEmpty) => {
	const { item, applyValue } = e;
	const intl = useIntl();

	const [value, setValue] = useState(e?.item?.value || '');

	const handleFilterChange = (event) => {
		applyValue({ ...item, value: event.target.value });
		setValue(event.target.value);
	};

	return (
		<>
			<FormControl variant="standard">
				<InputLabel htmlFor="mui-EmptyNotEmptyFilterValue" data-shrink="true">
					<FormattedMessage {...messages.value} />
				</InputLabel>
				<Select
					value={value}
					id="mui-EmptyNotEmptyFilterValue"
					onChange={handleFilterChange}
					label={<FormattedMessage {...messages.value} />}
				>
					{allowEmpty && <MenuItem key="empty" value=""></MenuItem>}
					{compareEmpty && <MenuItem value={'empty'}>{intl.formatMessage(messages.empty)}</MenuItem>}
					{compareEmpty && <MenuItem value={'notEmpty'}>{intl.formatMessage(messages.notEmpty)}</MenuItem>}
					{list
						.sort((a, b) => {
							const valueA = typeof a === 'string' ? a : a.name;
							const valueB = typeof b === 'string' ? b : b.name;
							if (valueA < valueB) return -1;
							else if (valueA > valueB) return 1;
							else return 0;
						})
						.map((item) => (
							<MenuItem
								key={typeof item === 'string' ? item : item.value}
								value={typeof item === 'string' ? item : item.value}
							>
								{typeof item === 'string' ? item : item.name}
							</MenuItem>
						))}
				</Select>
			</FormControl>
		</>
	);
};

const TimeLenghtFilterValue = ({ item, applyValue }, inputFormat) => {
	// Component works with ms and has to convert values in seconds if need be
	const [value, setValue] = useState(
		!isNaN(Number(item?.value))
			? dayjs.duration(inputFormat === 'ms' ? item.value : item.value * 1000)
			: dayjs.duration(0)
	);

	const handleInputChange = (value) => {
		const [hours, minutes, seconds] = value.split(':');

		const updatedDuration = dayjs.duration({ hours, minutes, seconds });
		setValue(updatedDuration);
	};

	const handleFilterChange = () => {
		applyValue({
			...item,
			value: inputFormat === 'ms' ? value.asMilliseconds() : value.asSeconds(),
		});
	};

	return (
		<TextField
			value={value.format('HH:mm:ss')}
			onChange={(e) => handleInputChange(e.target.value)}
			onBlur={handleFilterChange}
			onKeyDown={(e) => {
				if (e.code === 'Enter') handleFilterChange();
			}}
			defaultValue="00:00:00"
			label={'hh:mm:ss'}
			variant="standard"
			inputProps={{ step: '30' }}
			type="time"
		/>
	);
};

export const prepareExams = (intl, list) =>
	jsKeywordsToWords(
		list.map((_item) => {
			const item = { ..._item };

			if (item.hasOwnProperty('status')) item.status = item.status || '-';
			if (item.hasOwnProperty('jobStatus')) item.jobStatus = item.jobStatus || '-';
			if (item.hasOwnProperty('reviewerReview')) item.reviewerReview = item.reviewerReview || '-';
			if (item.hasOwnProperty('commissionerReview')) item.commissionerReview = item.commissionerReview || '-';
			if (item.hasOwnProperty('finalReview')) item.finalReview = item.finalReview || '-';
			if (item.hasOwnProperty('isViolationIntentional'))
				item.isViolationIntentional = item.isViolationIntentional || '-';
			if (item.hasOwnProperty('postponed')) item.postponed = item.postponed || '-';
			if (item.hasOwnProperty('consistency')) item.consistency = item.consistency || '-';
			if (item.hasOwnProperty('crossTermConsistency')) item.crossTermConsistency = item.crossTermConsistency || '-';

			item.durationFilterable = new Date(item.endedAt).getTime() - new Date(item.startedAt).getTime();
			item.duration = msToTime(item.durationFilterable);
			item.userRole = item.userRole && item.userRole !== '-' ? intl.formatMessage(messages[item.userRole]) : '-';

			return item;
		})
	);

export const prepareUsers = (list) => {
	const _list = list.map((_item) => {
		const item = { ..._item };

		if (item.hasOwnProperty('phoneNumber')) item.phoneNumber = item.phoneNumber || '-';
		if (item.hasOwnProperty('has2fa')) item.has2fa = item.has2fa || '-';
		if (item.hasOwnProperty('role')) item.role = item.role || '-';
		if (item.hasOwnProperty('skill')) item.skill = item.skill || '-';

		return item;
	});

	return jsKeywordsToWords(_list);
};

export const selectOperatorsWEmpty = (emails, intl) => [
	{
		label: intl.formatMessage(messages.is),
		value: 'is',
		getApplyFilterFn: (filterItem) => {
			if (!filterItem.field || !filterItem.value || !filterItem.operator) {
				return null;
			}

			return (params) => {
				switch (filterItem.value) {
					case 'empty':
						return isEmpty(params.value) || !params.value || params.value === '-';
					case 'notEmpty':
						return !isEmpty(params.value) && !!params.value && params.value !== '-';
					default:
						return params.value === filterItem.value;
				}
			};
		},
		InputComponent: (e) => EmptyNotEmptyFilterValue(e, emails, true),
		InputComponentProps: { type: 'singleSelect' },
	},
];

export const selectOperators = (list, intl, allowEmpty = false) => [
	{
		label: intl.formatMessage(messages.is),
		value: 'is',
		getApplyFilterFn: (filterItem) => {
			if (!filterItem.field || !filterItem.value || !filterItem.operator) {
				return null;
			}

			return (params) => {
				return params.value === filterItem.value;
			};
		},
		InputComponent: (e) => EmptyNotEmptyFilterValue(e, list, false, allowEmpty),
		InputComponentProps: { type: 'singleSelect' },
	},
];

export const timeLengthOperators = (inputFormat) => [
	{
		label: ' < ',
		value: '<',
		getApplyFilterFn: (filterItem) => {
			if (!filterItem.field || !filterItem.value || !filterItem.operator) return null;
			return (params) => params.value < filterItem.value;
		},
		InputComponent: (e) => TimeLenghtFilterValue(e, inputFormat),
		InputComponentProps: { type: 'singleSelect' },
	},
	{
		label: ' > ',
		value: '>',
		getApplyFilterFn: (filterItem) => {
			if (!filterItem.field || !filterItem.value || !filterItem.operator) return null;
			return (params) => params.value > filterItem.value;
		},
		InputComponent: (e) => TimeLenghtFilterValue(e, inputFormat),
		InputComponentProps: { type: 'singleSelect' },
	},
];

export const numericOperators = (intl) => {
	const defaultNumericOperators = getGridNumericOperators();
	const removedOperators = ['isAnyOf', 'isEmpty', 'isNotEmpty'];
	return [...defaultNumericOperators.filter(({ value }) => !removedOperators.includes(value))];
};

export const stringOperatorsExtended = (intl) => {
	const defaultStringOperators = getGridStringOperators();
	const removedOperators = ['isAnyOf', 'isEmpty'];
	return [
		...defaultStringOperators.filter(({ value }) => !removedOperators.includes(value)),
		{
			label: intl.formatMessage(messages.filterOperatorIsEmpty),
			value: 'isEmpty',
			getApplyFilterFn: (filterItem) => {
				if (!filterItem.field || !filterItem.value || !filterItem.operator) {
					return null;
				}

				return (params) => {
					return filterItem.value === intl.formatMessage(messages.yes)
						? isEmpty(params.value) || !params.value || params.value === '-'
						: !isEmpty(params.value) && !!params.value && params.value !== '-';
				};
			},
			InputComponent: GridFilterInputSingleSelect,
			InputComponentProps: { type: 'singleSelect' },
		},
	];
};
