import { Button, TextField, Paper, Grid, Alert, AlertTitle } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import { create2FATokenRequest } from '../api';
import { ROUTES } from '../consts';
import messages from '../intl/messages';
import { loginUser2FA } from '../store/auth/actions';
import { getIsUserLoggedIn, getUser } from '../store/auth/selectors';
import { setPageTitle } from '../store/ui/actions';
import useInterval from '../utils/useInterval';

const useStyles = makeStyles()((theme) => ({
	bounding: {
		textAlign: 'center',
		'& .container': {
			padding: '2rem',
			textAlign: 'left',
			display: 'inline-block',
			minWidth: '250px',
			width: '25%',
			margin: '50px auto',
			'& p': {
				margin: '0 0 20px 0',
				fontSize: '11px',
			},
		},
		'& .code-container': {
			display: 'flex',
			alignItems: 'center',
			'& .MuiButton-root': {
				marginLeft: '1em',
			},
		},
		'& .counter-container': {
			paddingTop: '5px !important',
		},
	},
}));

const TwoFactorAuthentication = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const intl = useIntl();

	const user = useSelector(getUser);
	const isUserLoggedIn = useSelector(getIsUserLoggedIn);

	const [code, setCode] = useState('');
	const [tokenId, setTokenId] = useState('');
	const [remainingTimeCount, setRemainingTimeCount] = useState(120);
	const [isCounterRunning, setIsCounterRunning] = useState(false);
	const [hasCodeExpired, setHasCodeExpired] = useState(false);
	const [reset, setReset] = useState();

	const { classes } = useStyles();

	const formatCounter = (seconds) => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds - minutes * 60;
		const secondsWithPadding = remainingSeconds.toString().length === 1 ? `0${remainingSeconds}` : remainingSeconds;

		return `${minutes}:${secondsWithPadding}`;
	};

	const startRemainingTimeCount = () => {
		setCode('');
		setIsCounterRunning(true);
		setHasCodeExpired(false);
		setRemainingTimeCount(120);
	};

	const sendCode = async () => {
		// API responds with a token and sends SMS to the user
		const { tokenId } = await create2FATokenRequest(user.id);
		setTokenId(tokenId);

		startRemainingTimeCount();
	};

	useEffect(() => {
		sendCode();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isUserLoggedIn) navigate(ROUTES.DASHBOARD.PATH);
	}, [isUserLoggedIn, navigate]);

	useEffect(() => {
		dispatch(setPageTitle(intl.formatMessage(messages.login)));
	}, [dispatch, intl]);

	useInterval(
		() => {
			if (remainingTimeCount === 1) {
				setHasCodeExpired(true);
				return setIsCounterRunning(false);
			}
			setRemainingTimeCount(remainingTimeCount - 1);
		},
		1000,
		isCounterRunning
	);

	const handleLogin = () =>
		dispatch(loginUser2FA({ userId: user.id, code, tokenId, callback: () => navigate(ROUTES.DASHBOARD.PATH) }));

	const forceUpdate = useCallback(() => setReset(+new Date()), []);

	// Reset timer when tab gets back active
	useEffect(() => {
		document.addEventListener('visibilitychange', () => {
			setTimeout(() => forceUpdate(), 1);
		});
	}, [forceUpdate]);

	return (
		<div className={classes.bounding} key={reset}>
			<Paper elevation={3} className="container">
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Alert severity="info">
							<AlertTitle>
								<FormattedMessage {...messages.twoFAHeading} />
							</AlertTitle>
							<FormattedMessage {...messages.twoFAPrompt} />
						</Alert>
					</Grid>
					<Grid item xs={12} className="code-container">
						<TextField
							label={<FormattedMessage {...messages.twoFACode} />}
							value={code}
							onChange={(e) => setCode(e.target.value)}
						/>
						<Button variant="text" size="small" color="primary" onClick={sendCode}>
							<FormattedMessage {...messages.resend} />
						</Button>
					</Grid>
					<Grid item xs={12} className="counter-container">
						{isCounterRunning ? (
							<p>
								<FormattedMessage {...messages.codeExpiresIn} /> <b>{formatCounter(remainingTimeCount)}</b>
							</p>
						) : hasCodeExpired ? (
							<p>
								<FormattedMessage {...messages.codeHasExpired} />
							</p>
						) : (
							<p></p>
						)}
						<Button variant="contained" color="primary" onClick={handleLogin}>
							<FormattedMessage {...messages.login} />
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
};

export default TwoFactorAuthentication;
