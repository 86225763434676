import { FileDownload, FileOpen } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, CircularProgress, Paper } from '@mui/material';
import { isEmpty } from 'lodash';
import { omit } from 'ramda';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getFilteredExams as getFilteredExamsApiCall } from '../../api';
import {
	ENTITIES,
	EXAM,
	JOB_STATUS,
	COMMISSIONER_REVIEW,
	YES_NO_VALUE_OPTIONS,
	REVIEWER_REVIEW,
	ROUTES,
} from '../../consts';
import messages from '../../intl/messages';
import { fetchUsers } from '../../store/auth/actions';
import { getUsers } from '../../store/auth/selectors';
import { fetchFilteredExams } from '../../store/model/actions';
import {
	getDefNamesForExamFilter,
	getDefinitions,
	getFilteredExams,
	getFilteredExamsCount,
	getTermsForExamFilter,
} from '../../store/model/selectors';
import { setXGridConfig } from '../../store/ui/actions';
import { getXgridConfig } from '../../store/ui/selectors';
import formatDate, { msToTime, toDateCzLocalized } from '../../utils/formatDate';
import {
	jsKeywordsToWords,
	prepareExams,
	selectOperatorsWEmpty,
	selectOperators,
	stringOperatorsExtended,
	timeLengthOperators,
	numericOperators,
} from '../../utils/prepareXGridData';
import DataGrid from '../DataGrid';
import {
	getCommissionerReviewIcon,
	getJobStatusIcon,
	getReviewerReviewIcon,
	getStatusIcon,
	useStyles,
} from '../Exams/ExamsList';
import UniButtton from '../UniButtton';
import XGridCustomToolbar from '../XGridCustomToolbar';

/**
 *	Customize filter model in favor to API needs
 *
 * @param object filterModel
 * @param array users
 * @returns customized filterModel
 */
const prepareFilterModel = (filterModel, users, intl) => {
	const filterModelPrepared = { ...filterModel };
	const extendedItems = [];

	filterModelPrepared.items = filterModelPrepared.items.map((_item) => {
		const item = { ..._item };

		// API needs to know users ids to be able to filter in columns assignedTo, reviewerReviewedBy, commissionerReviewedBy
		if (item.field === 'assignedTo' || item.field === 'reviewerReviewedBy' || item.field === 'commissionerReviewedBy') {
			if (item.value === 'empty' || item.value === 'notEmpty') {
				item.operator = 'isEmpty';
				item.value = item.value === 'empty';
				return item;
			}

			// return userId of false as filter item value (return false in case it's supposed to be empty)
			item.value = users.filter((user) => user.email === item.value)?.[0]?.id ?? false;
		}

		if (item.value === intl.formatMessage(messages.no)) item.value = false;
		else if (item.value === intl.formatMessage(messages.yes)) item.value = true;
		else if (item.value === '-') {
			item.value = isEmpty;
			item.operator = 'isEmpty';
		}

		// There is no 'name' column in DB table, so we rather filter by definitionId instead
		if (item.field === 'definitionName') item.field = 'definitionId';

		// BE accepts only boolean or string, convert numbers
		if (typeof item.value === 'number') item.value = String(item.value);

		return omit(['fromInput'], item);
	});

	filterModelPrepared.items = [...filterModelPrepared.items, ...extendedItems];
	return filterModelPrepared;
};

let prevPage = 1;

const ExamFilter = () => {
	const intl = useIntl();
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const initFilteredExams = useSelector(getFilteredExams());
	const count = useSelector(getFilteredExamsCount());
	const pagesCount = Math.ceil(count / 100);
	const [filteredExams, setFilteredExams] = useState([]);
	const [isFetchingRowsForExport, setIsFetchingRowsForExport] = useState(false);
	const [page, setPage] = useState(prevPage);
	const xgridConfig = useSelector(getXgridConfig());
	const definitions = useSelector(getDefinitions());
	const terms = useSelector(getTermsForExamFilter());
	const definitionNames = useSelector(getDefNamesForExamFilter());
	const users = Object.values(useSelector(getUsers) || {});
	const emails = users.map((user) => user.email);
	const config = xgridConfig[ENTITIES.EXAMS_FILTER];
	const { filterModel } = config;
	const counterCurrent = page === 1 ? page : (page - 1) * 100 + 1;
	const gridRef = useRef();
	const navigate = useNavigate();

	useEffect(() => {
		setFilteredExams(initFilteredExams);
	}, [initFilteredExams]);

	useEffect(() => {
		if (prevPage !== page) {
			setFilteredExams([]);
			dispatch(
				fetchFilteredExams({
					filterModel: prepareFilterModel(filterModel, users, intl),
					page,
					intl,
					sortModel: config.sortModel[0],
				})
			);
			prevPage = page;
		}
	}, [dispatch, filterModel, intl, page, users, terms, config.sortModel]);

	useEffect(() => {
		setFilteredExams([]);
	}, [filterModel]);

	useEffect(() => {
		dispatch(fetchUsers());
	}, [dispatch]);

	// XGrid configuration - START
	const columns = useMemo(() => {
		const columns = [
			{
				field: 'detail',
				headerName: '',
				width: 55,
				resizable: false,
				filterable: false,
				sortable: false,
				pinnable: false,
				disableExport: true,
				renderCell: (params) => (
					<UniButtton
						onClick={() => navigate(`${ROUTES.EXAMS.PATH}/${params?.row?.definitionId}/${params?.row?.id}`)}
						type="icon"
						icon={<FileOpen />}
						tooltip={<FormattedMessage {...messages.detail} />}
					/>
				),
			},
			{
				field: 'definitionId',
				headerName: intl.formatMessage(messages.definitionId),
				width: 320,
				cellClassName: 'cursorAuto',
				type: 'string',
				filterable: false,
			},
			{
				field: 'definitionName',
				headerName: intl.formatMessage(messages.definition),
				width: 320,
				cellClassName: 'cursorAuto',
				// Filtering is done using definitionIds and not names themselves
				valueGetter: (params) => params.row.definitionId,
				// However, names are the thing displayed and exported
				renderCell: (params) => definitions[params.row.definitionId]?.name,
				valueFormatter: ({ value }) => definitions[value]?.name,
				type: 'singleSelect',
				filterOperators: selectOperators(definitionNames, intl),
			},
			{
				field: 'term',
				headerName: intl.formatMessage(messages.term),
				width: 180,
				renderCell: (params) => definitions[params.row.definitionId].term,
				type: 'singleSelect',
				filterOperators: selectOperators(terms, intl),
			},
			{
				field: 'isOnboarding',
				headerName: intl.formatMessage(messages.isOnboarding),
				width: 180,
				valueGetter: (params) =>
					definitions[params.row.definitionId]?.isOnboarding
						? intl.formatMessage(messages.yes)
						: intl.formatMessage(messages.no),
				type: 'singleSelect',
				filterOperators: selectOperators(YES_NO_VALUE_OPTIONS, intl, false),
			},
			{
				field: 'firstName',
				headerName: intl.formatMessage(messages.firstName),
				width: 180,
				type: 'string',
				valueOptions: [intl.formatMessage(messages.yes), intl.formatMessage(messages.no)],
				filterOperators: stringOperatorsExtended(intl),
			},
			{
				field: 'lastName',
				headerName: intl.formatMessage(messages.lastName),
				width: 180,
				type: 'string',
				valueOptions: [intl.formatMessage(messages.yes), intl.formatMessage(messages.no)],
				filterOperators: stringOperatorsExtended(intl),
			},
			{
				field: 'dateOfBirth',
				headerName: intl.formatMessage(messages.birthDate),
				width: 180,
				valueFormatter: ({ value }) => toDateCzLocalized(value),
				type: 'date',
			},
			{
				field: 'status',
				headerName: intl.formatMessage(messages.status),
				width: 180,
				renderCell: (params) => <div>{getStatusIcon(params.row.status, intl)}</div>,
				type: 'singleSelect',
				filterOperators: selectOperators(Object.values(EXAM), intl, false),
			},
			{
				field: 'jobStatus',
				headerName: intl.formatMessage(messages.processingStatus),
				width: 180,
				renderCell: (params) => <div>{getJobStatusIcon(params.row.jobStatus, intl)}</div>,
				type: 'singleSelect',
				filterOperators: selectOperators(Object.values(JOB_STATUS), intl, false),
			},
			{
				field: 'finalReview',
				headerName: intl.formatMessage(messages.finalReview),
				width: 230,
				renderCell: (params) => <div>{getCommissionerReviewIcon(params.row.finalReview)}</div>,
				type: 'singleSelect',
				filterOperators: selectOperators(COMMISSIONER_REVIEW, intl, false),
			},
			{
				field: 'finalViolationMessage',
				headerName: intl.formatMessage(messages.finalViolationMessage),
				width: 170,
				type: 'string',
				valueOptions: [intl.formatMessage(messages.yes), intl.formatMessage(messages.no)],
				filterOperators: stringOperatorsExtended(intl),
			},
			{
				field: 'violationNote',
				headerName: intl.formatMessage(messages.violationNote),
				width: 230,
				type: 'string',
				valueOptions: [intl.formatMessage(messages.yes), intl.formatMessage(messages.no)],
				filterOperators: stringOperatorsExtended(intl),
			},
			{
				field: 'evaluationDetail',
				headerName: intl.formatMessage(messages.evaluationDetail),
				width: 230,
				type: 'string',
				valueOptions: [intl.formatMessage(messages.yes), intl.formatMessage(messages.no)],
				filterOperators: stringOperatorsExtended(intl),
			},
			{
				field: 'finalViolationCode',
				headerName: intl.formatMessage(messages.finalViolationCode),
				width: 150,
				align: 'right',
				type: 'number',
				headerAlign: 'right',
				filterOperators: numericOperators(intl),
			},
			{
				field: 'isViolationIntentional',
				headerName: intl.formatMessage(messages.intentionalViolation),
				width: 210,
				type: 'singleSelect',
				filterOperators: selectOperators(YES_NO_VALUE_OPTIONS, intl, false),
			},
			{
				field: 'commissionerReview',
				headerName: intl.formatMessage(messages.commissionerReview),
				width: 230,
				renderCell: (params) => <div>{getCommissionerReviewIcon(params.row.commissionerReview)}</div>,
				type: 'singleSelect',
				filterOperators: selectOperators(COMMISSIONER_REVIEW, intl, false),
			},
			{
				field: 'commissionerReviewedBy',
				headerName: intl.formatMessage(messages.commissionerReviewedBy),
				width: 240,
				type: 'singleSelect',
				filterOperators: selectOperatorsWEmpty(emails, intl),
				valueOptions: [],
			},
			{
				field: 'commissionerReviewedAt',
				headerName: intl.formatMessage(messages.commissionerReviewedAt),
				width: 280,
				renderCell: (params) => <div>{formatDate(params.row.commissionerReviewedAt)}</div>,
				valueGetter: (params) => new Date(params.row.commissionerReviewedAt),
				type: 'dateTime',
			},
			{
				field: 'commissionerReviewDurationSec',
				headerName: intl.formatMessage(messages.commissionerReviewDurationSec),
				filterOperators: timeLengthOperators('ss'),
				renderCell: (params) => <div>{msToTime(params.row.commissionerReviewDurationSec * 1000)}</div>,
				type: 'singleSelect',
				width: 220,
			},
			{
				field: 'reviewerReview',
				headerName: intl.formatMessage(messages.reviewerReview),
				width: 230,
				renderCell: (params) => <div>{getReviewerReviewIcon(params.row.reviewerReview)}</div>,
				type: 'singleSelect',
				filterOperators: selectOperators(REVIEWER_REVIEW, intl, false),
			},
			{
				field: 'reviewerReviewedBy',
				headerName: intl.formatMessage(messages.reviewerReviewedBy),
				width: 240,
				type: 'singleSelect',
				filterOperators: selectOperatorsWEmpty(emails, intl),
				valueOptions: [],
			},
			{
				field: 'reviewerReviewedAt',
				headerName: intl.formatMessage(messages.reviewerReviewedAt),
				width: 280,
				renderCell: (params) => <div>{formatDate(params.row.reviewerReviewedAt)}</div>,
				valueGetter: (params) => new Date(params.row.reviewerReviewedAt),
				type: 'dateTime',
			},
			{
				field: 'reviewerReviewDurationSec',
				headerName: intl.formatMessage(messages.reviewerReviewDurationSec),
				filterOperators: timeLengthOperators('ss'),
				renderCell: (params) => <div>{msToTime(params.row.reviewerReviewDurationSec * 1000)}</div>,
				type: 'singleSelect',
				width: 220,
			},
			{
				field: 'aiViolationsCount',
				headerName: intl.formatMessage(messages.aiViolationsCount),
				width: 150,
				align: 'right',
				type: 'number',
				headerAlign: 'right',
				filterOperators: numericOperators(intl),
			},
			{
				field: 'customViolationsCount',
				headerName: intl.formatMessage(messages.customViolationsCount),
				width: 200,
				align: 'right',
				type: 'number',
				headerAlign: 'right',
				filterOperators: numericOperators(intl),
			},
			{
				field: 'startedAt',
				headerName: intl.formatMessage(messages.startedAt),
				width: 180,
				valueFormatter: ({ value }) => formatDate(value),
				valueGetter: (params) => new Date(params.row.startedAt),
				type: 'dateTime',
			},
			{
				field: 'endedAt',
				headerName: intl.formatMessage(messages.endedAt),
				width: 180,
				valueFormatter: ({ value }) => formatDate(value),
				valueGetter: (params) => new Date(params.row.endedAt),
				type: 'dateTime',
			},
			{
				field: 'postponed',
				headerName: intl.formatMessage(messages.postponedReview),
				width: 200,
				type: 'singleSelect',
				filterOperators: selectOperators(YES_NO_VALUE_OPTIONS, intl, false),
			},
			{
				field: 'assignedTo',
				headerName: intl.formatMessage(messages.assignedTo),
				width: 240,
				type: 'singleSelect',
				filterOperators: selectOperatorsWEmpty(emails, intl),
			},
			{
				field: 'userRole',
				headerName: intl.formatMessage(messages.userRoleAssignedTo),
				width: 240,
				type: 'singleSelect',
				valueOptions: [
					intl.formatMessage(messages.reviewer),
					intl.formatMessage(messages.commissioner),
					intl.formatMessage(messages.superadmin),
				],
			},
			{
				field: 'consistency',
				headerName: intl.formatMessage(messages.consistency),
				width: 160,
				type: 'singleSelect',
				filterOperators: selectOperators(YES_NO_VALUE_OPTIONS, intl, false),
			},
			{
				field: 'crossTermConsistency',
				headerName: intl.formatMessage(messages.crossTermConsistency),
				width: 260,
				type: 'singleSelect',
				filterOperators: selectOperators(YES_NO_VALUE_OPTIONS, intl, false),
			},
			{
				field: 'id',
				headerName: intl.formatMessage(messages.examId),
				disableClickEventBubbling: true,
				width: 320,
				cellClassName: 'cursorAuto',
				type: 'string',
				valueOptions: [intl.formatMessage(messages.yes), intl.formatMessage(messages.no)],
				filterOperators: stringOperatorsExtended(intl),
			},
			{
				field: 'customerId',
				headerName: intl.formatMessage(messages.customerId),
				width: 320,
				renderCell: (params) => <div>{params.row.customerId}</div>,
				type: 'string',
				valueOptions: [intl.formatMessage(messages.yes), intl.formatMessage(messages.no)],
				filterOperators: stringOperatorsExtended(intl),
			},
			{
				field: 'externalId',
				headerName: intl.formatMessage(messages.externalId),
				disableClickEventBubbling: true,
				width: 310,
				cellClassName: 'cursorAuto',
				type: 'string',
				valueOptions: [intl.formatMessage(messages.yes), intl.formatMessage(messages.no)],
				filterOperators: stringOperatorsExtended(intl),
			},
		];

		return columns;
	}, [definitionNames, definitions, emails, intl, navigate, terms]);

	const dataGridConfig = {
		gridId: ENTITIES.EXAMS_FILTER,
		filterMode: 'server',
		components: {
			Toolbar: () => (
				<XGridCustomToolbar disableExport={true} gridId={ENTITIES.EXAMS_FILTER}>
					{isFetchingRowsForExport ? (
						<CircularProgress size={30} style={{ marginLeft: '30px' }} disableShrink />
					) : (
						<Button
							onClick={() => handleExport({ fileName: ENTITIES.EXAMS_FILTER, delimiter: ';', utf8WithBom: true })}
							// Export needs to know how many total rows there potentially are in the db,
							// this ensures that filter is always sent first so that we receive this information about total rows count
							disabled={!filteredExams.length}
						>
							<FileDownload />
							<span>Export</span>
						</Button>
					)}
					<Button
						className="rightFloatAction"
						variant="contained"
						color="primary"
						disabled={!filterModel.items.length}
						onClick={() => handleExamFilter()}
					>
						<FormattedMessage {...messages.filterExams} />
					</Button>
				</XGridCustomToolbar>
			),
		},
		rows: prepareExams(intl, jsKeywordsToWords(filteredExams)),
		onFilterModelChange: (e) => {
			// store filter model config
			dispatch(setXGridConfig({ ...config, filterModel: e }, ENTITIES.EXAMS_FILTER));
			setFilteredExams([]);
		},
		onSortModelChange: (e) => {
			dispatch(setXGridConfig({ ...config, sortModel: e }, ENTITIES.EXAMS_FILTER));
			handleExamFilter(e[0]);
		},
		columns,
		hideFooterPagination: true,
		hideFooterRowCount: true,
		apiRef: gridRef,
	};
	// XGrid configuration - END

	const handleExamFilter = (sortModel) => {
		if (!filterModel.items.length || isFetchingRowsForExport) return;

		setFilteredExams([]);
		if (page === 1)
			dispatch(
				fetchFilteredExams({
					filterModel: prepareFilterModel(filterModel, users, intl),
					page,
					intl,
					// Use newest sortModel when called from sorting handler
					// Otherwise, use the currently applied sortModel (if any)
					sortModel: sortModel || config.sortModel[0],
				})
			);
		else setPage(1);
	};

	const handleExport = (csvOptions) => {
		if (!filterModel.items.length) return;

		setIsFetchingRowsForExport(true);

		getFilteredExamsApiCall(prepareFilterModel(filterModel, users, intl), page, count).then(({ exams }) => {
			setFilteredExams(prepareExams(intl, jsKeywordsToWords(exams)));
			setIsFetchingRowsForExport(false);

			setTimeout(() => {
				gridRef?.current.exportDataAsCsv(csvOptions);
			}, 1000);
		});
	};

	return (
		<Paper elevation={3} className={classes.bounding} style={{ height: '750px' }}>
			<h2>{intl.formatMessage(messages.filtering)}</h2>
			<div style={{ height: '695px' }}>
				<DataGrid {...dataGridConfig} />
			</div>

			{!isEmpty(filteredExams) && (
				<div className="pagination">
					<span className="total-count">
						{counterCurrent} - {page * 100 > count ? count : page * 100} <FormattedMessage {...messages.from} /> {count}
					</span>
					<UniButtton
						icon={<ArrowBackIosIcon size="small" />}
						className={classes.arrows}
						tooltip={<FormattedMessage {...messages.previous} />}
						onClick={() => setPage(page === 1 ? 1 : page - 1)}
					/>
					{page} / {Math.ceil(pagesCount)}
					<UniButtton
						icon={<ArrowForwardIosIcon size="small" />}
						className={classes.arrows}
						tooltip={<FormattedMessage {...messages.next} />}
						onClick={() => setPage(page >= pagesCount ? page : page + 1)}
					/>
				</div>
			)}
		</Paper>
	);
};

export default ExamFilter;
