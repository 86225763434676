import { Delete } from '@mui/icons-material';
import { TextField, Select, MenuItem } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { CHECKPOINT_GROUP } from '../../consts';
import messages from '../../intl/messages';
import { hideModal } from '../../store/modal/actions';
import { addProcess, deleteProcess, editProcess } from '../../store/model/actions';
import { getConfigDefinitionId } from '../../store/model/selectors';
import UniButtton from '../UniButtton';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles()(() => ({
	deleteIcon: {
		float: 'right',
	},
}));

const ProcessModal = ({ process }) => {
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const creatingMode = isEmpty(process);

	const processGroup = [CHECKPOINT_GROUP.PROCESS_ANALYSIS, CHECKPOINT_GROUP.CHROME_EXTENSIONS_ANALYSIS];

	const [name, setName] = useState(creatingMode ? '' : process.name);
	const [checkpointGroup, setCheckpointGroup] = useState(CHECKPOINT_GROUP.PROCESS_ANALYSIS);
	const [processes, setProcesses] = useState(creatingMode ? '' : process.config?.processes.join(', '));
	const definitionId = useSelector(getConfigDefinitionId);

	const parseProcessesIntoArray = (processes) =>
		processes
			.replace(/,/g, '')
			.split(' ')
			.map((process) => process.trim());

	const handleAddProcess = () => {
		const parsedProcesses = parseProcessesIntoArray(processes);

		dispatch(addProcess({ name, processes: parsedProcesses, checkpointGroup }, definitionId));
		dispatch(hideModal());
	};

	const handleEditProcess = () => {
		const parsedProcesses = parseProcessesIntoArray(processes);

		dispatch(editProcess(process.id, { name, processes: parsedProcesses }, definitionId));
		dispatch(hideModal());
	};

	const handleDeleteProcess = () => {
		dispatch(deleteProcess(process.id, definitionId));
		dispatch(hideModal());
	};

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages[creatingMode ? 'addProcess' : 'editProcess']} />}
			saveBtnTitle={<FormattedMessage {...messages[creatingMode ? 'addProcess' : 'editProcess']} />}
			onSave={creatingMode ? handleAddProcess : handleEditProcess}
		>
			<TextField
				value={name}
				label={<FormattedMessage {...messages.processName} />}
				onChange={(e) => setName(e.target.value)}
				variant="outlined"
				size="small"
				fullWidth
			/>
			<br />
			<br />
			{creatingMode && (
				<>
					<Select value={checkpointGroup} onChange={(e) => setCheckpointGroup(e.target.value)} fullWidth>
						{processGroup.map((group) => (
							<MenuItem key={group} value={group}>
								{group}
							</MenuItem>
						))}
					</Select>
					<br />
					<br />
				</>
			)}
			<TextField
				value={processes}
				label={<FormattedMessage {...messages.processes} />}
				onChange={(e) => setProcesses(e.target.value)}
				variant="outlined"
				fullWidth
			/>
			<UniButtton
				className={classes.deleteIcon}
				tooltip={<FormattedMessage {...messages.deleteProcess} />}
				color="primary"
				onClick={handleDeleteProcess}
				icon={<Delete fontSize="small" />}
			/>
		</SaveCloseModalTemplate>
	);
};

export default ProcessModal;
