const cs = {
	cs: {
		'global.remove': 'Odstranit',
		'global.archive': 'Archivovat',
		'global.active': 'Aktivní',
		'global.expiredCode': 'Neplatný kód',
		'global.resetChangePasswordTokenDesc': 'Platnost kódu pro obnovení hesla vypršela. Můžete si nechat zaslat nový.',
		'global.back': 'Zpět',
		'global.notifOperationSuccess': 'Operace byla úspěšně provedena',
		'global.deleteArchivedExams': 'Odstranit archivované zkoušky',
		'global.deleteArchivedExamsPrompt': 'Opravdu chcete trvale smazat všechny zkoušky v této definici?',
		'global.unknown': '?',
		'global.superadmin': 'Superadmin',
		'global.sysadmin': 'Sysadmin',
		'global.admin': 'Admin',
		'global.bulkReviewOk': 'Hromadně akceptovat',
		'global.download': 'Stáhnout',
		'global.play': 'Hrát',
		'global.pause': 'Pauza',
		'global.rewind': 'Přetočit zpět',
		'global.forward': 'Přetočit dopředu',
		'global.mute': 'Vypnout zvuk',
		'global.unmute': 'Zapnout zvuk',
		'global.fullscreen': 'Celá obrazovka',
		'global.pictureInPicture': 'Obraz v obraze',
		'global.idCheckpointStartedAt': 'ID',
		'global.faceCheckpointStartedAt': 'Obličej',
		'global.roomCheckpointStartedAt': 'Místnost',
		'global.examCheckpointStartedAt': 'Zkouška',
		'global.yes': 'Ano',
		'global.no': 'Ne',
		'global.is': 'je',
		'global.isNot': 'není',
		'global.empty': 'prázdné',
		'global.notEmpty': 'neprázdné',
		'global.violationInfo': `
			<p><strong>{comment}</strong></p>
			<p>Název pravidla: <strong>{checkpointName}</strong></p>
			<p>Typ pravidla: <strong>{checkpointType}</strong></p>
		`,
		'global.addViolation': 'Přidat porušení',
		'global.deleteViolation': 'Smazat porušení',
		'global.confirmReview': 'Potvrdit hodnocení',
		'global.confirmClusterStop': 'Potvrdit vypnutí clusteru',
		'global.confirmReviewDone':
			'Určitě chcete odeslat hodnocení této zkoušky? Odesláním potvrzujete, že jste pečlivě zkontroloval/a všechny záznamy.',
		'global.confirmReviewUndone': 'Některá porušení nejsou ohodnocena:',
		'global.copyDefinition': 'Zkopírovat definici',
		'global.archiveDefinition': 'Archivovat definici',
		'global.unarchiveDefinition': 'Odarchivovat definici',
		'global.archiveDefinitionPrompt':
			'Při archivování definice budou uživatelům skryty všechny zkoušky a dojde k nenávratnému smazání všech fotek dokladů totožnosti. Videa ze zkoušky budou přesunuta do Cool tier. Definici lze později odarchivovat.',
		'global.unarchiveDefinitionPrompt':
			'Při odarchivování definice budou uživatelům zpřístupněny všechny zkoušky. Videa zůstanou uložena v Cool tier.',
		'global.removeDefinition': 'Odstranit definici',
		'global.hours': 'Hodin',
		'global.minutes': 'Minut',
		'global.seconds': 'Sekund',
		'global.violationTitle': 'Název porušení',
		'global.violationNote': 'Poznámka k porušení',
		'global.violationComment': 'Komentář k porušení',
		'global.type': 'Typ',
		'global.editUser': 'Upravit uživatele',
		'global.userRole': 'Role',
		'global.shouldSendEmail': 'Zaslat e-mail',
		'global.has2fa': '2F ověření',
		'global.phoneNumber': 'Telefonní číslo',
		'global.userRoleAssignedTo': 'Role (přiřazeno k)',
		'global.userSkill': 'Dovednost',
		'global.instructionsText': 'Text instrukce',
		'global.headline': 'Titulek',
		'global.position': 'Pořadí',
		'global.dashboard': 'Dashboard',
		'global.durationInSecs': 'Délka (v sekundách)',
		'global.duration': 'Délka',
		'global.runCheck': 'Fáze kontroly',
		'global.enabled': 'Povolit',
		'global.editInstructionsText': 'Editovat instrukce',
		'global.dragToSort': 'Změnit pořadí',
		'global.saveChecklist': 'Uložit nastavení kontrol',
		'global.saveAllCheckpoints': 'Uložit nastavení pravidel',
		'global.checklist': 'Kontroly',
		'global.uploading': 'Nahrávám',
		'global.noChanges': 'Žádné změny',
		'global.saveDefinition': 'Uložit nastavení definice',
		'global.dates': 'Vstupní okno',
		'global.isArchived': 'Archivováno',
		'global.logViolatedOnly': 'Zaznamenávat pouze porušení',
		'global.isOnboarding': 'Onboarding',
		'global.GDPR': 'Souhlas',
		'global.realtimeNotification': 'Notifikace při vypnutí AI',
		'global.proctoringNotification': 'Notifikace při vypnutí Proctoring Desktop',
		'global.rules': 'Pravidla',
		'global.breadcrumbDefinition': `<p>definitionId: <strong>{id}</strong></p>`,
		'global.breadcrumbExam': `
			<h3>Student</h3>
			<p>customerId: <strong>{customerId}</strong></p>
			<p>examId: <strong>{examId}</strong></p>
			<p>externalId: <strong>{externalId}</strong></p>
			<p>Jméno: <strong>{firstName}</strong></p>
			<p>Příjmení: <strong>{lastName}</strong></p>
		`,
		'global.breadcrumbDefinitions': 'Definice',
		'global.login': 'Přihlásit se',
		'global.createPassword': 'Vytvořit heslo',
		'global.passwordRules': 'Heslo musí obsahovat: alespoň 8 znaků, velké písmeno a speciální znak',
		'global.resend': 'Znovu odeslat',
		'global.codeExpiresIn': 'Platnost kódu vyprší za',
		'global.codeHasExpired': 'Platnost kódu vypršela, vygenerujte si nový',
		'global.forgotPassword': 'Zapomenuté heslo',
		'global.twoFACode': '2FA kód',
		'global.send': 'Odeslat',
		'global.id': 'ID',
		'global.name': 'Jméno',
		'global.startsAt': 'Začátek',
		'global.endsAt': 'Konec',
		'global.status': 'Stav zkoušky',
		'global.firstName': 'Jméno',
		'global.lastName': 'Příjmení',
		'global.startedAt': 'Zahájeno',
		'global.endedAt': 'Ukončeno',
		'global.processingStatus': 'Stav AI kontroly',
		'global.reviewStatus': 'Stav hodnocení',
		'global.definitions': 'Definice',
		'global.exam': 'Zkouška',
		'global.exams': 'Zkoušky',
		'global.done': 'Odeslat',
		'global.camera': 'Kamera',
		'global.screen': 'Obrazovka',
		'global.identification': 'Identifikace',
		'global.room': 'Místnost',
		'global.birthDate': 'Datum narození',
		'global.writeYourNote': 'Napište poznámku',
		'global.addNote': 'Přidat poznámku',
		'global.editNote': 'Editovat poznámku',
		'global.deleteNote': 'Smazat poznámku',
		'global.deleteNotePrompt': 'Opravdu chcete smazat poznámku?',
		'global.filterBy': 'Filtrovat',
		'global.commissioner': 'Komisař',
		'global.reviewer': 'Hodnotitel',
		'global.createNewUser': 'Vytvořit nového uživatele',
		'global.email': 'E-mail',
		'global.delete': 'Odstranit',
		'global.resetPassword': 'Obnovit heslo',
		'global.users': 'Uživatelé',
		'global.createNewPassword': 'Vytvořit nové heslo',
		'global.password': 'Heslo',
		'global.confirmPassword': 'Zopakovat heslo',
		'global.confirmPasswordNotEqual': 'Hesla se neshodují.',
		'global.passwordTooShort': 'Heslo musí obsahovat aspoň {number} znaků.',
		'global.passwordNoCapital': 'Heslo musí obsahovat aspoň 1 velké písmeno.',
		'global.passwordNoSpecialCharacter': 'Heslo musí obsahovat aspoň 1 speciální znak.',
		'global.twoFAHeading': 'Dvoufaktorové ověření',
		'global.twoFAPrompt': 'Zadejte prosím 6místný kód, který vám přišel v SMS.',
		'global.checkListAndCheckpoints': 'Kontroly & Pravidla',
		'global.actions': 'Akce',
		'global.statistics': 'Statistika',
		'global.listOfTemplates': 'Seznam zkoušek',
		'global.currentExams': 'Aktuální zkoušky',
		'global.definition': 'Definice',
		'global.editGDPR': 'Upravit souhlas',
		'global.editRules': 'Upravit pravidla',
		'global.rulesText': 'Znění pravidel',
		'global.GdprText': 'Znění souhlasu',
		'global.nameSubject': 'Název / Zkouška',
		'global.checkpoints': 'Pravidla',
		'global.addProcess': 'Přidat zakázaný proces',
		'global.editProcess': 'Upravit zakázaný proces',
		'global.deleteProcess': 'Smazat zakázaný proces',
		'global.processName': 'Název procesu',
		'global.processes': 'Procesy',
		'global.streamType': 'Zdroj záznamu',
		'global.checkpointGroup': 'Skupina pravidel',
		'global.evaluationGroup': 'Skupina porušení',
		'global.confidanceTreshold': 'Míra jistoty',
		'global.confidanceTresholdLow': 'Míra jistoty (nižší)',
		'global.frameOccurance': 'Počet snímků',
		'global.violationMessage': 'Text porušení',
		'global.finalViolationMessage': 'Hlavní porušení',
		'global.assetMissing': 'Zdroj chybí',
		'global.refreshExams': 'Obnovit zkoušky',
		'global.postponeReview': 'Odložit zkoušku',
		'global.postponedReview': 'Odloženo',
		'global.aiViolationsCount': 'AI porušení',
		'global.customViolationsCount': 'Nalezená porušení',
		'global.intentionalViolation': 'Porušení je úmyslné',
		'global.resetPostponedReviews': 'Vrátit odložené zkoušky k review',
		'global.resetAssignedReviews': 'Znovu přiřadit zkoušky k review',
		'global.display': 'Zobrazit',
		'global.assignedTo': 'Přiřazeno k',
		'global.date': 'Termín',
		'global.examId': 'ExamId',
		'global.externalId': 'ExternalId',
		'global.detail': 'Detail',
		'global.definitionId': 'DefinitionId',
		'global.priority': 'Priorita',
		'global.reviewedExamsCount': 'Počet přezkoumaných zkoušek',
		'global.changeText': 'Změnit text',
		'global.previous': 'Zpátky',
		'global.next': 'Další',
		'global.plainHtml': 'Prostý HTML',
		'global.postponed': 'Odloženo a rozpracováno',
		'global.filtering': 'Filtrování',
		'global.reviewed': 'Dohodnocené',
		'global.term': 'Termín',
		'global.instructions': 'Instrukce',
		'global.target': 'Cíl',
		'global.consistency': 'Konzistence',
		'global.crossTermConsistency': 'Konzistence napříč termíny',
		'global.customerId': 'CustomerId',
		'global.removeUser': 'Smazat uživatele',
		'global.sureToRemoveUser': 'Jste si jisti odebráním uživatele',
		'global.activateUser': 'Aktivovat uživatele',
		'global.deactivateUser': 'Deaktivovat uživatele',
		'global.sureToActivateUser': 'Jste si jisti aktivací uživatele',
		'global.sureToDeactivateUser': 'Jste si jisti deaktivací uživatele',
		'global.reviewerReviewedBy': 'Prověřil hodnotitel',
		'global.commissionerReviewedBy': 'Prověřil komisař',
		'global.proceedToExams': 'Přistoupit ke zkouškám',
		'global.all': 'Vše',
		'global.groups': 'Skupiny',
		'global.onlySuspicious': 'Pouze podezřelé',
		'global.onlyUnreviewed': 'Pouze nezkontrolováno',
		'global.close': 'Zavřít',
		'global.examSettings': 'Nastavení zkoušek',
		'global.saveInstructions': 'Uložit instrukce',
		'global.logout': 'Odhlásit se',
		'global.rootGridLabel': 'Mřížka',
		'global.noRowsLabel': 'Žádné řádky',
		'global.errorOverlayDefaultLabel': 'Vyskytla se chyba.',
		'global.toolbarDensity': 'Hustota zobrazení',
		'global.toolbarDensityLabel': 'Hustota zobrazení',
		'global.toolbarDensityCompact': 'Kompaktní',
		'global.toolbarDensityStandard': 'Standardní',
		'global.toolbarDensityComfortable': 'Komfortní',
		'global.toolbarColumns': 'Sloupce',
		'global.toolbarColumnsLabel': 'Vyberte sloupce',
		'global.toolbarFilters': 'Filtry',
		'global.toolbarFiltersLabel': 'Zobrazit filtry',
		'global.toolbarFiltersTooltipHide': 'Skrýt filtry',
		'global.toolbarFiltersTooltipShow': 'Zobrazit filtry',
		'global.toolbarExport': 'Export',
		'global.toolbarExportLabel': 'Export',
		'global.toolbarExportCSV': 'Stáhnout jako CSV',
		'global.columnsPanelTextFieldLabel': 'Najít sloupec',
		'global.columnsPanelTextFieldPlaceholder': 'Název sloupce',
		'global.columnsPanelDragIconLabel': 'Změnit pořadí sloupce',
		'global.columnsPanelShowAllButton': 'Ukázat vše',
		'global.columnsPanelHideAllButton': 'Skrýt vše',
		'global.filterPanelAddFilter': 'Přidat filtr',
		'global.filterPanelDeleteIconLabel': 'Vymazat',
		'global.filterPanelOperators': 'Operátory',
		'global.filterPanelOperatorAnd': 'A',
		'global.filterPanelOperatorOr': 'Anebo',
		'global.filterPanelColumns': 'Sloupce',
		'global.filterPanelInputLabel': 'Hodnota',
		'global.filterPanelInputPlaceholder': 'Hodnota filtru',
		'global.filterOperatorIsEmpty': 'je prázdný',
		'global.filterOperatorIsNotEmpty': 'není prázdný',
		'global.filterOperatorContains': 'obsahuje',
		'global.filterOperatorEquals': 'se rovná',
		'global.filterOperatorStartsWith': 'začíná s',
		'global.filterOperatorEndsWith': 'končí s',
		'global.filterOperatorIs': 'je',
		'global.filterOperatorNot': 'není',
		'global.filterOperatorAfter': 'je po',
		'global.filterOperatorOnOrAfter': 'je v anebo po',
		'global.filterOperatorBefore': 'je před',
		'global.filterOperatorOnOrBefore': 'je v nebo před',
		'global.columnMenuLabel': 'Menu',
		'global.columnMenuShowColumns': 'Zobrazit sloupce',
		'global.columnMenuFilter': 'Filter',
		'global.columnMenuHideColumn': 'Skrýt',
		'global.columnMenuUnsort': 'Neřadit',
		'global.columnMenuSortAsc': 'Seřadit vzestupně',
		'global.columnMenuSortDesc': 'Seřadit sestupně',
		'global.columnHeaderFiltersLabel': 'Zobrazit filtry',
		'global.columnHeaderSortIconLabel': 'Seřadit',
		'global.pinToLeft': 'Připnout vlevo',
		'global.pinToRight': 'Připnout vpravo',
		'global.unpin': 'Odepnout',
		'global.footerTotalRows': 'Celkem řádků',
		'global.checkboxSelectionHeaderName': 'Výběr zaškrtávacího políčka',
		'global.activeFilter': 'aktivní filtr',
		'global.activeFilters': 'aktivní filtry',
		'global.rowSelected': 'řádek',
		'global.rowsSelected': 'řádeky',
		'global.commissionerReview': 'Hodnocení komisaře',
		'global.reviewerReview': 'Hodnocení hodnotitele',
		'global.violation': 'Porušení',
		'global.other': 'Jiné',
		'global.participantsCount': 'Počet zkoušených',
		'global.expectedExams': 'Očekávané',
		'global.parallelJobsCount': 'Počet paralelních jobů',
		'global.scaling': ' Škálování',
		'global.nodepool': 'Nodepool',
		'global.scalingExam': 'Škálování zkouška',
		'global.scalingPostprocessing': 'Škálování postprocessing',
		'global.minCount': 'Min. počet',
		'global.maxCount': 'Max. počet',
		'global.currentCount': 'Aktuální počet',
		'global.autoScaling': 'Automatické',
		'global.manualScaling': 'Manuální',
		'global.database': 'Databáze',
		'global.cpu': 'CPU',
		'global.storageMb': 'Úložiště MB',
		'global.switchAINodepools': 'Zapnout AI nodepooly',
		'global.switchBackendAutoscaling': 'Škálovat backend',
		'global.switchDbAutoscaling': 'Škálovat databázi',
		'global.switchAutoscaler': 'Zapnout autoškálování',
		'global.runningExams': 'Probíhajících zkoušek:',
		'global.runningJobs': 'Zkoušek ve frontě:',
		'global.running': 'Probíhající',
		'global.finished': 'Dokončené',
		'global.aksCluster': 'AKS cluster',
		'global.autoscaler': 'Autoškálování',
		'global.start': 'Start',
		'global.stop': 'Stop',
		'global.clusterName': 'Název',
		'global.clusterStatus': 'Stav',
		'global.cpuLoad': 'Vytížení CPU',
		'global.application': ' Aplikace',
		'global.required': 'Požadováno',
		'global.wrongPhoneNumberFormat': 'Nesprávný formát (e.g. +420911222333)',
		'global.currentStatus': ' Aktuální stav',
		'global.hardware': ' Hardware',
		'global.csvExport': 'Export do CSV',
		'global.reviewerReviewedAt': 'Čas hodnocení (hodnotitel)',
		'global.commissionerReviewedAt': 'Čas hodnocení (komisař)',
		'global.finalViolationCode': 'Kód porušení',
		'global.createNewFromDefault': 'Nová z výchozího nastavení',
		'global.default': 'Výchozí',
		'global.setDefault': 'Nastavit výchozí',
		'global.total': 'Celkem',
		'global.processed': 'Zpracované',
		'global.assignReviews': 'Přiřadit hodnocení',
		'global.processExams': 'Znovu zpracovat',
		'global.finishExam': 'Ukončit zkoušku',
		'global.finishExamPrompt': 'Potvrďte manuální ukončení zkoušky.',
		'global.selectUser': 'Vyberte uživatele',
		'global.removeExams': 'Smazat zkoušky',
		'global.sureToRemoveExams': 'Jste si jisti smazáním vybraných zkoušek?',
		'global.sureToFinishDefinitionExams': 'Opravdu chcete ukončit všechny zkoušky?',
		'global.filterExams': 'Filtrovat zkoušky',
		'global.finishExams': 'Dokončit zkoušky',
		'global.commissionerQueue': 'Fronta komisaře',
		'global.reviewerQueue': 'Fronta hodnotitele',
		'global.noQueue': 'Momentálně není ve frontě žádná zkouška',
		'global.finalReview': 'Konečné hodnocení',
		'global.from': 'z',
		'global.noteTypeVIOLATION': 'Hodnotitel',
		'global.noteTypeNOTE': 'Komisař',
		'global.noteTypeFEEDBACK': 'Pozitivní feedback',
		'global.noteTypeNEGATIVE_FEEDBACK': 'Negativní feedback',
		'global.noteTypeBOARD': 'Odvolací komise',
		'global.value': 'Hodnota',
		'global.unassignReview': 'Zrušit přiřazení',
		'global.sendAndClose': 'Odeslat a zavřít',
		'global.loading': 'Načítá se ...',
		'global.assignToReview': 'Přiřadit k hodnocení',
		'global.evaluationDetail': 'Podrobnosti o porušení',
		'global.previousUserExams': 'Předchozí zkoušky uživatele',
		'global.commissionerReviewDurationSec': 'Délka hodnocení (komisař)',
		'global.reviewerReviewDurationSec': 'Délka hodnocení (hodnotitel)',
		'global.deleteViolationPrompt': 'Opravdu chcete toto porušení smazat?',
		'global.copyExamUrl': 'Zkopírovat URL adresu zkoušky',
		'global.copiedIntoClipboard': 'zkopírováno do schránky!',
		'global.monitoring': 'Monitoring',
		'global.addSubcheck': 'Přidat dílčí kontrolu',
		'global.scalingHistory': 'Historie škálování',
		'global.isManualScaling': 'Je manuální škálování',
		'global.wasScaledByLoad': 'Byl zmenšen podle zatížení',
		'global.notes': 'Poznámky',
		'global.note': 'Poznámka',
		'global.authorEmail': 'Email autora',
		'global.createdAt': 'Vytvořeno v',
		'global.idPhotoNotAvailable': 'Fotografie dokladu není k dispozici.',
		'global.facePhotoNotAvailable': 'Fotografie zkousené osoby není k dispozici.',
		'global.settings': 'Nastavení',
		'global.noteType': 'Typ poznámky',
		'global.negative_feedback': 'Negativní zpětná vazba',
		'global.positive_feedback': 'Pozitivní zpětná vazba',
		'global.appeals_board': 'Odvolací komise',
		'global.panel': 'Panel',
		'global.logoutIn': 'Odhlášení proběhne za',
		'global.sendNewPassword': 'Odeslat nové heslo',
		'global.webcam': 'Kamera',
		'global.video/room': 'Místnost',
		'global.myExamCounter': 'Počítadlo mých zkoušek',
		'global.introChecksTimer': 'Časomíra v úvodních kontrolách',
		'global.timerLimit': 'Limit časomíry (minuty)',
		'global.timerDisplay': 'Zobrazení časomíry (minuty)',
		'global.timerWarning': 'Varování časomíry (minuty)',
		'global.archiveAfterDays': 'Archivovat za (dny)',
		'global.deleteAfterDays': 'Smazat za (dny)',
		'global.reprocessExams': 'Zpracovat zkoušky',
		'global.reprocessExamsAgain': 'Znovu zpracovat zkoušky',
		'global.reprocessExamsAgainConfirm': 'Jste si jisti, že chcete znovu zpracovat vybrané zkoušky?',
		'global.operatingSystem': 'Operační systém',
		'global.deferredLimit': 'Limit odložených',
		'global.webcamVideoNotFound': 'Video z webkamery není k dispozici.',
		'global.roomVideoNotFound': 'Video ze skenu místnosti není k dispozici.',
		'global.screenVideoNotFound': 'Video z obrazovky není k dispozici.',
		'global.defaultSettings': 'Výchozí nastavení',
	},
};

export default cs;
